.recurly-element, .recurly-hosted-field {
    background-color: #fff;
    display: inline-block;
    width: 100%;
    border-radius: 8px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: relative;
    border: 1px solid #E9E9E9;
    color: #000000;
    font-size: 16px;
    height: 54px;
    padding-left: 15px;
    cursor: text;
}

.recurly-element-focus, .recurly-hosted-field-focus {
    border: 1px solid #E9E9E9;
}

.recurly-element-invalid, .recurly-hosted-field-invalid {
    border: 1px solid #e35256
}

.recurly-element-number, .recurly-hosted-field-number {
}

.recurly-element-cvv, .recurly-element-month, .recurly-element-year, .recurly-hosted-field-cvv, .recurly-hosted-field-month, .recurly-hosted-field-year {
}