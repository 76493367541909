@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("../fonts/OpenSans/OpenSans-SemiBold.woff2") format('woff2');
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/OpenSans/OpenSans-Regular.woff2") format('woff2');
}
@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/PlayfairDisplay/PlayfairDisplay-Regular.woff2") format('woff2');
}
@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("../fonts/PlayfairDisplay/PlayfairDisplay-SemiBold.woff2") format('woff2');
}