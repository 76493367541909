* {
    outline: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

*::before,
*::after {
    box-sizing: border-box;
}

svg {
    /** Fix large viewBox and translates in IE */
    overflow: hidden;
}

#__next {
    display: flex;
}

html, body {
    width: 100%;
    min-width: 320px;
    background-color: #f8f5f1;
}

input, textarea {
    -webkit-appearance: none;
    border-radius: 0;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

input::-ms-clear {
    display: none !important;
}

input::-ms-reveal {
    display: none !important;
}

button {
    border: 0;
    background-color: transparent;
    cursor: pointer;
}

main {
    display: block;
}

ul,
ol,
nav {
    list-style-type: none;
}

a {
    color: inherit;
    text-decoration: none;
    transition: 0.3s;
}

a:hover, a:focus {
    text-decoration: none;
}

img {
    display: block;
    max-width: 100%;
}

body {
    font-size: 14px;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body, button{
    font-family: 'Open Sans',
    -apple-system,
    'BlickMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
}